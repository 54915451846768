import { Box } from "@mui/material";

const Blog = () => {
    return (
      <Box>
        Blog Route
        </Box>
    )
}

export default Blog;