import React, { useState } from "react";
import { useLogin, useSignUp } from "../../api/useLogin";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
// import LoginIcon from '@mui/icons-material/Login';

import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import GoogleLoginComponent from "./GoogleLoginComponent";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate, useLocation } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import "./Login.css";
import { GoogleIcon, prepareLogin, prepareSignUp } from "./loginhelpers";

const Login = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);
  const handleRememberMeChange = (event) => setRememberMe(event.target.checked);
  const navigate = useNavigate();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: "/" } };

  const loginMutation = useLogin();
  const signUpMutation = useSignUp();

  const handleLogin = (e) => {
    e.preventDefault();
    if (prepareLogin(email, password)) {
      const user = { email, password };
      loginMutation.mutate(user, {
        onSuccess: () => {
          navigate(from.pathname);
        },
      });
    }
  };
  const handleSignUp = () => {
    if (prepareSignUp(email, password)) {
      signUpMutation.mutate({ email, password });
    }
  };

  return (
    <Box className="login-container">
      <Box className="margin"></Box>
      <Box className="margin"></Box>
      <Box className="margin"></Box>
      <Box className="margin"></Box>
      <Box className="login-box">
        <form onSubmit={handleLogin}>
          <Stack direction="column" spacing={1}>
            <Box sx={{ textAlign: "center" }}>
              <FormControl sx={{ m: 1, width: "95%" }} variant="outlined">
                <InputLabel>Email</InputLabel>
                <OutlinedInput
                  type={"text"}
                  value={email}
                  onChange={handleEmailChange}
                  label="Username"
                  inputProps={{ autoComplete: 'username' }}  // Add this line
                />
              </FormControl>
            </Box>

            <Box sx={{ textAlign: "center" }}>
              <FormControl sx={{ m: 1, width: "95%" }} variant="outlined">
                <InputLabel>Password</InputLabel>
                <OutlinedInput
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={handlePasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  autoComplete="current-password"
                />
              </FormControl>
              <Box sx={{ textAlign: "center", marginBottom: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                      name="rememberMe"
                      color="primary"
                    />
                  }
                  label="Remember me"
                />
              </Box>
            </Box>

            <Box sx={{ textAlign: "center" }}>
              <Button
                type="submit"
                sx={{
                  backgroundColor: "#313131",
                  "&:hover": {
                    backgroundColor: "#1f1f1f",
                  },
                }}
                variant="contained"
                // endIcon={<LoginIcon />}
              >
                Login
              </Button>
            </Box>

            <Box className="or">OR</Box>

            <Box sx={{ textAlign: "center" }}>
              <GoogleLoginComponent
                style={{
                  backgroundColor: "#313131",
                  "&:hover": {
                    backgroundColor: "#1f1f1f",
                  },
                }}
                variant="contained"
                startIcon={<GoogleIcon />}
                from={from}
              />
            </Box>

            <Box className="or">OR</Box>

            <Box sx={{ textAlign: "center" }}>
              <Button
                sx={{
                  backgroundColor: "#313131",
                  "&:hover": {
                    backgroundColor: "#1f1f1f",
                  },
                }}
                variant="contained"
                onClick={handleSignUp}
              >
                Sign Up
              </Button>
            </Box>
          </Stack>
        </form>
      </Box>
      <Box className="margin"></Box>
      <Box className="margin"></Box>
      <Box className="margin"></Box>
      <Box className="margin"></Box>
    </Box>
  );
};

export default Login;
