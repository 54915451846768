const validatePassword =(password)=> {
  /*
  In this function, we use a regular expression to test the password for our requirements.
  The re.test(password) will return a boolean indicating if the password matches the requirements or not.

  The regular expression used in this example validates that the password:

  Has at least one lowercase character (?=.*[a-z])
  Has at least one uppercase character (?=.*[A-Z])
  Has at least one digit (?=.*\d)
  Has at least one special character (?=.*[@$!%*?&])
  Is at least 8 characters in length [A-Za-z\d@$!%*?&]{8,}
  Please note that this is just an example, you should adjust the regular expression and requirements to fit your application's security needs.

  You can use this function in your onLogin and onSignUp methods to validate the password before making the API calls.
  */
  const re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return re.test(password);
}



const validateEmail=(email)=> {
  /*
  This regular expression considers an email to be well-formed if it:

  Starts with one or more alphanumeric characters, periods (.), underscores (_), percent signs (%), plus signs (+), or hyphens (-).
  Follows that with the at sign (@).
  Then has one or more alphanumeric characters, periods (.), or hyphens (-).
  Ends with a period (.) followed by two or more alphabetic characters.
  */
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase());
}


export const prepareLogin = (email, password) => {
  if (!validateEmail(email)) {
      alert("Invalid email or password!");
      return false;
  }
  return true;
}

export const prepareSignUp = (email, password) => {
  if (!validateEmail(email) || !validatePassword(password)) {
      alert("Invalid email or password!");
      return false;
  }
  return true;
}

export const GoogleIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18">
      <path
        d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18Z"
        fill="#4285F4"
      />
      <path
        d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17Z"
        fill="#34A853"
      />
      <path
        d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07Z"
        fill="#FBBC05"
      />
      <path
        d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3Z"
        fill="#EA4335"
      />
    </svg>
  );
};


