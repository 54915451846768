import { useQuery } from "@tanstack/react-query";
import Api from "./api";

const fetchJepx = async ({ queryKey }) => {
  const  url = queryKey[1];
  const response = await Api({
    method: "GET",
    url: url,
    headers: {
      'X-API-KEY': 'o-fNhv4qEBfPJnDfRCtB3REiNeumQofkvlF9WDsy'
    }

  });
  return response.data;
};

const getBase = (apitype, date) => {
  const formattedDate = date.toISOString().split("T")[0];
  switch (apitype) {
    case "jepx":
      return `jepx/spot?xdate=${formattedDate}`;
    case "intraday":
      return `jepx/intraday?xdate=${formattedDate}`;
    default:
      throw new Error("invalid apitype");
  }
};

const useJepx = (apitype, date) => {
  const url = getBase(apitype, date);
  return useQuery([apitype, url], fetchJepx);
};

export default useJepx;
