import React from 'react';
import { AppBar, Toolbar, IconButton, InputBase, Badge, Avatar, Menu, MenuItem, Box } from '@mui/material';
import { SvgIcon } from '@mui/material';

// SVG Icons
const MenuIcon = (props) => (
    <SvgIcon {...props}>
        <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
    </SvgIcon>
);

const SearchIcon = (props) => (
    <SvgIcon {...props}>
        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
    </SvgIcon>
);

const NotificationIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path>
    </SvgIcon>
);

const Test = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static" color="default">
            <Toolbar>
                <IconButton edge="start" color="inherit">
                    <MenuIcon />
                </IconButton>
                <Box sx={{ flexGrow: 1 }}>
                    <InputBase
                        placeholder="Search"
                        inputProps={{ 'aria-label': 'search' }}
                        startAdornment={<SearchIcon />}
                    />
                </Box>
                <IconButton color="inherit">
                    <Badge badgeContent={4} color="primary">
                        <NotificationIcon />
                    </Badge>
                </IconButton>
                <IconButton edge="end" onClick={handleMenuOpen} color="inherit">
                    <Avatar alt="Profile Picture" src="../assets/img/team/profile-picture-3.jpg" />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    {/* Add your menu items here */}
                    <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
                    <MenuItem onClick={handleMenuClose}>My account</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}

export default Test;
