import axios from "axios";


const client = axios.create({
    baseURL: "https://seknowledgebank.com/api/v1/",
    timeout: 5000,
    withCredentials: true,
    responseType: 'json'
});

const Api = async ({ ...options }) => {
    //　client.defaults.headers.common.Authorization = 'Bearer token'
    const onSuccess = response => response;
    const onError = error => {
        // Optionally catch errors and add additional logging here
        return Promise.reject(error.response || error.message);
    }
    return client(options).then(onSuccess).catch(onError)
}

export default Api;





