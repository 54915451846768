import Api from "./api";
import { useMutation } from "@tanstack/react-query";

const check_success = (data) => {
  const status= data.data;
  if (status.status === "success") {
    localStorage.setItem("authenticated", status.unique_id);
  } else {
    let errorMessage = data.detail[0].msg || data.detail;
    alert("Sign up failed. " + errorMessage);
  }
};

export const FetchAuth = () => {
  return Api({
    method: "GET",
    url: "auth/google/read_token",
    withCredentials: true,
  });
};

export const getToken = (email, password) => {
  return Api({
    method: "POST",
    url: "/token",
    data: {
      username: email,
      password: password,
    },
  });
};

export const signUpUser = (email, password) => {
  return Api({
    method: "POST",
    url: "/user",
    headers: { "Content-Type": "application/json" },
    data: {
      email: email,
      password: password,
      name: email,
    },
  });
};

const refreshToken = () => {
  return Api({
    method: "POST",
    url: "auth/google/token_refresh",
    withCredentials: true, // ensure that cookies are sent with the request
  });
};

const logoutUser = () => {
  return Api({
    method: "POST",
    url: "auth/user/logout",
  });
};

const LoginUser = (user) => {
  return Api({
    method: "POST",
    url: "auth/user/login",
    data: user,
  });
};

const getGoogleToken = (googleToken) => {
  return Api({
    method: "POST",
    url: "auth/google/token",
    data: { token: googleToken },
  });
};

export const useTokenRefresh = (onAuthenticated) => {
  const mutationTokenRefresh = useMutation(refreshToken, {
    onSuccess: (data) => {
      // If the token refresh is successful, you might want to update any user-related state or UI based on the response.
      // For now, it's kept simple.
      onAuthenticated(true);
      console.log("Token refreshed successfully.");
    },
    onError: (error) => {
      console.error("Error during token refresh:", error);
      // alert("Failed to refresh token. Please log in again.");
    },
  });

  return mutationTokenRefresh;
};

export const useGoogler = () => {
  const mutationGoogleLogin = useMutation(getGoogleToken, {
    onSuccess: (data) => {
      check_success(data);
    },
    onError: (error) => {
      console.error("Error during Google login:", error);
      alert("Failed to log in. " + error);
    },
  });

  return mutationGoogleLogin;
};

export const useLogout = () => {
  const mutationLogout = useMutation(logoutUser, {
    onSuccess: (data) => {
      // If the token refresh is successful, you might want to update any user-related state or UI based on the response.
      // For now, it's kept simple.
      console.log("logged out");
    },
    onError: (error) => {
      console.error("Error during token refresh:", error);
      // alert("Failed to refresh token. Please log in again.");
    },
  });
  return mutationLogout;
};



export const useLogin = () => {
  const mutationLogin = useMutation(LoginUser, {
    onSuccess: (data) => {
      check_success(data);
    },
    onError: (error) => {
      alert("Failed to log in. " + error);
    },
  });
  return mutationLogin;
};

export const useSignUp = () => {
  const mutationSignUp = useMutation(signUpUser, {
    onSuccess: (data) => {
      if (data.status.status === "success") {
        localStorage.setItem("authenticated", data.data.unique_id);
      } else {
        let errorMessage = data.detail[0].msg || data.detail;
        alert("Sign up failed. " + errorMessage);
      }
    },
    onError: (error) => {
      alert("Failed to sign up. " + error);
    },
  });

  return mutationSignUp;
};
