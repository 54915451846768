// pages/todo/Todo.js

import {Box} from "@mui/material";


// https://demo.themesberg.com/volt-pro/pages/kanban.html
const Todo = () => {


    return (
    <Box></Box>
    );
  };

  export default Todo;