import React, { useEffect, useRef } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const CandlestickChart = ({ data }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        if (data) {
            const formattedData = transformCandlestickData(data.price);
            plotAmChart(formattedData);
        }
        return () => {
            if (chartRef.current) {
                chartRef.current.dispose();
            }
        };
    }, [data]);

    const transformCandlestickData = (prices) => {
        return prices.map(price => {
            return {
                datetime: new Date(price.datetime.replace(' ', 'T')),
                open: price.open,
                close: price.close,
                low: price.low,
                high: price.high,
                average: price.average
            };
        });
    };

    const plotAmChart = (data) => {

        if (chartRef.current) {
            chartRef.current.dispose();
        }

        let chart = am4core.create("chartdiv", am4charts.XYChart);

        // chart.dateFormatter.inputDateFormat = "yyyy-MM-ddTHH:mm:ss";

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.title.text = "Datetime";
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.baseInterval = { timeUnit: 'minute', count: 30 };

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "Intraday Price (JPY/kWh)";
        // valueAxis.tooltip.disabled = true;

        const series = chart.series.push(new am4charts.CandlestickSeries());
        series.dataFields.dateX = "datetime";
        series.dataFields.valueY = "close";
        series.dataFields.openValueY = "open";
        series.dataFields.lowValueY = "low";
        series.dataFields.highValueY = "high";
        series.tooltipText = "Open: {openValueY.value}\nLow: {lowValueY.value}\nHigh: {highValueY.value}\nClose: {valueY.value}";
        // Add a line series for the average values
        let averageSeries = chart.series.push(new am4charts.LineSeries());
        averageSeries.dataFields.dateX = "datetime";
        averageSeries.dataFields.valueY = "average";
        averageSeries.name = "Average";
        averageSeries.strokeWidth = 2;
        averageSeries.stroke = am4core.color("#ff0000"); // Change to any color you prefer
        averageSeries.tooltipText = "Average: {valueY}";

        // If you want to add bullets to the average line on hover:
        let bullet = averageSeries.bullets.push(new am4charts.CircleBullet());
        bullet.circle.radius = 4;
        bullet.circle.strokeWidth = 2;
        bullet.circle.fill = am4core.color("#fff");

        // Legend
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";

        // Cursor
        chart.cursor = new am4charts.XYCursor();

        chart.data = data;

        chartRef.current = chart;
    };

    return (
        <div id="chartdiv" style={{ width: "100%", height:"80vh"}}></div>
    );
};

export default CandlestickChart;
