import React from 'react';
import Plotly from 'plotly.js-basic-dist';
import createPlotlyComponent from 'react-plotly.js/factory';


const Plot = createPlotlyComponent(Plotly);

const PlotlyComponent = ({data,selectedDate}) => {

    const layout = {
        autosize: true,
        title: 'JEPX spot Market',
        xaxis:{title:"Datetime"},
        yaxis:{title:"Spot Price (JPY/kWh"},

    };

    const transformData = () => {
        const regions = ["hokkaido", "tohoku", "tokyo", "chubu", "hokuriku", "kansai", "chugoku", "shikoku", "kyushu", "system"];
        return regions.map(region => {
            return {
                x: data.price.map(p => p.datetime),
                y: data.price.map(p => p[region]),
                type: 'scatter',
                mode: 'lines',
                name: region
            };
        });
    };

    return (
        <div  style={{ width: '100vw', height: '100vh' }}>
            {data && <Plot data={transformData()} layout={layout} style={{width: "90vw", height: "80vh"}}/>}
        </div>
    );
}

export default PlotlyComponent;
