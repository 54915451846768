import {useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PlotlyComponent from '../../components/charts/plotlycharts/Plotlycharts';
import D3Component from '../../components/charts/d3charts/d3jcharts';
import SpotAmcharts from '../../components/charts/amcharts/SpotAmCharts';
import CandlestickChart from '../../components/charts/amcharts/IntradayAmcharts';
import DateWithArrows from '../../components/datePicker/DateWithArros';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ProgressBar } from '../../components/common/progressBar';
import useJepx from '../../api/useJepx';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const   Jepx = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [chartDate, setChartDate]=useState(new Date());

    const chartType = selectedTab === 3 ? "intraday" : "jepx";
    const {data, isLoading, isError, isFetching} = useJepx(chartType, chartDate);


    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };



  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Box sx={{ width: '100%', height:'100vh' }}>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example"  centered>
          <Tab label="Spot AmCharts"{...a11yProps(0)} />
          <Tab label="Spot D3"  {...a11yProps(1)} />
          <Tab label="Spot Plotly" {...a11yProps(2)} />
          <Tab label="Intraday AmCharts" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <DateWithArrows selectedDate={chartDate} setSelectedDate={setChartDate} />
      {isLoading || isFetching ? (
                    <ProgressBar />
                ) : isError ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                        <Typography variant="h6" color="error">Error loading data</Typography>
                    </Box>
                ) : (
                    <>
                        <CustomTabPanel value={selectedTab} index={0}>
                            <SpotAmcharts data={data} selectedDate={chartDate} />
                        </CustomTabPanel>
                        <CustomTabPanel value={selectedTab} index={1}>
                            <D3Component data={data} selectedDate={chartDate} />
                        </CustomTabPanel>
                        <CustomTabPanel value={selectedTab} index={2}>
                            <PlotlyComponent data={data} selectedDate={chartDate} />
                        </CustomTabPanel>
                        <CustomTabPanel value={selectedTab} index={3}>
                            <CandlestickChart data={data}/>
                        </CustomTabPanel>
                    </>
                )}
            </Box>
    </LocalizationProvider>
  );
}

export default Jepx;
