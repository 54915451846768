import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const D3Component = ({ data, selectedDate }) => {
  const d3Container = useRef(null);

  useEffect(() => {
    if (data && d3Container.current) {
      const svg = d3.select(d3Container.current);
      svg.selectAll("*").remove(); // Clear the SVG

      const margin = { top: 20, right: 80, bottom: 30, left: 50 };
      const width =
        d3Container.current.clientWidth - margin.left - margin.right;
      const height =
        d3Container.current.clientHeight - margin.top - margin.bottom;

      const prices = [
        "hokkaido",
        "tohoku",
        "tokyo",
        "chubu",
        "hokuriku",
        "kansai",
        "chugoku",
        "shikoku",
        "kyushu",
        "system",
      ];
      const colors = d3.scaleOrdinal(d3.schemeCategory10);

      const x = d3
        .scaleUtc()
        .domain(d3.extent(data.price, (d) => new Date(d.datetime)))
        .range([0, width]);

      const y = d3
        .scaleLinear()
        .domain([
          0,
          d3.max(data.price, (d) =>
            Math.max(...prices.map((price) => d[price]))
          ),
        ])
        .nice()
        .range([height, 0]);

      const line = d3.line().x((d) => x(new Date(d.datetime)));

      const g = svg
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      g.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x));

      g.append("g").call(d3.axisLeft(y));

      prices.forEach((price, idx) => {
        g.append("path")
          .datum(data.price)
          .attr("fill", "none")
          .attr("stroke", colors(idx))
          .attr("stroke-width", 1.5)
          .attr(
            "d",
            line.y((d) => y(d[price]))
          );
      });

      // Legend
      const legend = g
        .selectAll(".legend")
        .data(prices)
        .enter()
        .append("g")
        .attr("class", "legend")
        .attr("transform", (d, i) => `translate(0,${i * 20})`);

      legend
        .append("rect")
        .attr("x", width + 5)
        .attr("y", -10)
        .attr("width", 10)
        .attr("height", 10)
        .style("fill", (d, i) => colors(i));

      legend
        .append("text")
        .attr("x", width + 20)
        .attr("y", 0)
        .attr("dy", ".35em")
        .text((d) => d);

      // Hover line
      const hoverLine = g
        .append("line")
        .attr("stroke", "#aaa")
        .attr("y1", 0)
        .attr("y2", height)
        .attr("pointer-events", "none")
        .style("display", "none");

      const hoverTexts = prices.map((price, idx) => {
        return g
          .append("text")
          .attr("fill", colors(idx))
          .attr("pointer-events", "none")
          .style("display", "none");
      });

      g.append("rect")
        .attr("class", "overlay")
        .attr("width", width)
        .attr("height", height)
        .style("fill", "none")
        .style("pointer-events", "all")
        .on("mouseover", () => {
          hoverLine.style("display", null);
          hoverTexts.forEach((text) => text.style("display", null));
        })
        .on("mouseout", () => {
          hoverLine.style("display", "none");
          hoverTexts.forEach((text) => text.style("display", "none"));
        })
        // On mousemove event for the overlay
        .on("mousemove", function (event) {
          const mouseX = d3.pointer(event)[0];
          const date = x.invert(mouseX);
          const bisector = d3.bisector(d => new Date(d.datetime)).left;
          const index = bisector(data.price, date);
          const d0 = data.price[index - 1];
          const d1 = data.price[index];
          const d = (!d1 || Date.parse(date) - Date.parse(d0.datetime) < Date.parse(d1.datetime) - Date.parse(date)) ? d0 : d1;

          hoverLine.attr("x1", x(new Date(d.datetime))).attr("x2", x(new Date(d.datetime)));

          // Capture all y-values at the current mouseX
          const yValues = prices.map(price => y(d[price]));

          // Sort y-values
          const sortedYValues = Array.from(yValues).sort((a, b) => a - b);

          hoverTexts.forEach((text, idx) => {
              const valueY = y(d[prices[idx]]);
              const sortedIndex = sortedYValues.indexOf(valueY);

              // Calculate yOffset based on its position in the sorted list
              const yOffset = sortedIndex * 20; // Adjust the multiplier to control the vertical spacing

              text.attr("x", x(new Date(d.datetime)) + 5)
                  .attr("y", valueY - yOffset)
                  .text(`${prices[idx]}: ${d[prices[idx]]}`);
          });
        });
    }
  }, [data]);

  return (
    <div style={{ width: "100%", height: "80vh" }}>
      <svg ref={d3Container} style={{ width: "100%", height: "100%" }} />
    </div>
  );
};

export default D3Component;
