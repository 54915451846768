import React, { useEffect, useRef} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";



am4core.useTheme(am4themes_animated);

const SpotAmcharts = ({data,selectedDate}) => {
    const chartRef = useRef(null); // Ref to store the chart instance

    useEffect(() => {
        if (data) {
            const formattedData = transformData(data.price);
            plotAmChart(formattedData);
        }
        // Cleanup function
        return () => {
            if (chartRef.current) {
                chartRef.current.dispose();
            }
        };
    }, [data, selectedDate]);

    const transformData = (prices) => {
        const regions = ["hokkaido", "tohoku", "tokyo", "chubu", "hokuriku", "kansai", "chugoku", "shikoku", "kyushu","system"];
        return regions.map(region => {
            return prices.map(price => ({ datetime: new Date(price.datetime), value: price[region] }));
        });
    };

    const plotAmChart = (formattedData) => {
        if (chartRef.current) {
            chartRef.current.dispose();
        }
        let chart =  am4core.create("chartdiv", am4charts.XYChart);

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.title.text = "Datetime";
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.baseInterval = { timeUnit: 'minute', count: 30 }; // Setting granularity to 30 minutes

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "Spot Price (JPY/kWh)";


        const regions = ["hokkaido", "tohoku", "tokyo", "chubu", "hokuriku", "kansai", "chugoku", "shikoku", "kyushu", "system"];
        formattedData.forEach((regionData, index) => {
            let series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.dateX = "datetime";
            series.dataFields.valueY = "value";
            series.data = regionData;
            series.name = regions[index];
            series.tooltipText = regions[index] + ": [bold]{valueY}[/]";

            // Bullets on hover
            let bullet = series.bullets.push(new am4charts.CircleBullet());
            bullet.circle.radius = 5;
            bullet.circle.strokeWidth = 2;
            bullet.circle.fill = am4core.color("#fff");
        });

        // Legend
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.cursor = new am4charts.XYCursor();

        chartRef.current = chart;
    };

    return (<div id="chartdiv" style={{ width: "100%", height: "80vh" }}></div>);
}


export default SpotAmcharts;