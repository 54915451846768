
import Box from '@mui/material/Box';


const Home = () => {
    return (
      <Box>
        Home Route
      </Box>
    )
}
export default Home;