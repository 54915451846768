import { Button, Box, CircularProgress, Grid } from "@mui/material";

function Circularindeterminate(props) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center", // corrected the typo here
        height: "100vh", // corrected the typo here
        flexDirection: "column",
      }}
    >
      <Box sx={{ height: 40 }}> {/* corrected the typo here */}
        <CircularProgress size="10rem" />
      </Box>
      <Box>
        <Button sx={{ m: 2 }} size="large">
          {props.status}
          {"..."}
        </Button>
      </Box>
    </Box>
  );
}

export const ProgressBar = ({ status }) => { // destructured status directly
  return (
    <Grid container spacing={0}
      direction='column'
      alignItems='center' // corrected the typo here
      justifyContent="center"
      style={{minHeight:'100vh'}}>
      <Grid item xs={12}>
        <Circularindeterminate status={status} />
      </Grid>
    </Grid>
  );
}
