

const ErrorPage = () => {

  return (
    <div>
      <h1>Oops! Something went wrong.</h1>
      <p>
        {
        // <i>{error.statusText || error.message}</i>*>
        }
      </p>
    </div>
  );
};


export default ErrorPage;