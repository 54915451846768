// GoogleLoginComponent.js

import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useGoogler } from "../../api/useLogin";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const GoogleLoginComponent = ({ style, variant, startIcon, from }) => {
  const [showLogin, setShowLogin] = useState(false);
  const navigate = useNavigate(); // Add this line to get the navigate function

  // Use the custom hook
  const googleLoginMutation = useGoogler();
  const handleGoogleSuccess = (credentialResponse) => {
    // Handle the successful Google OAuth response here...
    const googleToken = credentialResponse?.credential;
    if (googleToken) {
      googleLoginMutation.mutate(googleToken);
    }
    setShowLogin(false); // Hide the GoogleLogin component again after login
    // Navigate to the original location
    navigate(from.pathname); // Add this line to navigate
  };

  const handleGoogleFailure = () => {
    console.log("Login Failed");
    setShowLogin(false); // Hide the GoogleLogin component again after failure
  };

  const handleGoogleLoginClick = () => {
    // Trigger the Google login process
    setShowLogin(true); // Display the GoogleLogin component to trigger login
  };

  return (
    <div>
      {/* Hidden GoogleLogin component */}
      {showLogin && (
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={handleGoogleFailure}
          style={{ display: "none" }}
        />
      )}

      {/* Visible button that the user interacts with */}
      <Button
        onClick={handleGoogleLoginClick}
        style={style}
        variant={variant}
        startIcon={startIcon}
      >
        Continue with Google
      </Button>
    </div>
  );
};

export default GoogleLoginComponent;
