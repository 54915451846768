import React from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);


const DateWithArrows = ({selectedDate, setSelectedDate}) => {

    // Convert the naive date to a date assumed to be in "Asia/Tokyo"
    const toTokyoTimezone = (naiveDate) => {
        return dayjs.tz(naiveDate, "Asia/Tokyo");
    };


    const handlePreviousDay = () => {
        if (!(selectedDate instanceof Date)) {
            console.error("selectedDate is not a Date object")

        };
        const prevDay = dayjs.tz(selectedDate, "Asia/Tokyo").subtract(1, 'day').toDate();
        setSelectedDate(prevDay);

    };

    const handleNextDay = () => {
        const nextDay = dayjs.tz(selectedDate, "Asia/Tokyo").add(1, 'day').toDate();
        setSelectedDate(nextDay);
    };

    return (
        <Box  display="flex"  justifyContent="center">
            {/* Custom buttons for date navigation */}
            <Button onClick={handlePreviousDay}>&lt;</Button>
            <DatePicker
                value={toTokyoTimezone(selectedDate)}
                onChange={(date) => setSelectedDate(date)}
                timezone="Asia/Tokyo"
                components={{
        Input: TextField
    }}
                showToolbar
                toolbarFormat="MMMM D, YYYY"
            />
            <Button onClick={handleNextDay}>&gt;</Button>
        </Box>
    );
}

export default DateWithArrows;
