import React from "react";
import { Box, CssBaseline } from "@mui/material";
import ResponsiveAppBar from "./components/nav/Appbar";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import Blog from "./pages/blog/Blog";
import Meti from "./pages/meti/Meti";
import Jepx from "./pages/jepx/Jepx";
import Glosarry from "./pages/glossary/Glossary";
import Todo from "./pages/todo/Todo";
import Notes from "./pages/notes/Notes";
import ErrorPage from "./components/error/ErrorPage";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/auth/Auth";
import Bess from "./pages/bess/Bess";
import Test from "./pages/test/Test";



function App() {
  return (
    <Box>
      <CssBaseline />
      <ResponsiveAppBar/>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/meti" element={<Meti />} />
        <Route path="/jepx" element={<PrivateRoute><Jepx /></PrivateRoute>} />
        <Route path="/glossary" element={<Glosarry />} />
        <Route Path="/test" element={<Test/>} />
        {/* Private routes */}
        <Route path="/todo" element={<PrivateRoute><Todo /></PrivateRoute>} />
        <Route path="/notes" element={<PrivateRoute><Notes /></PrivateRoute>} />
        <Route path="/bess"  element={<PrivateRoute><Bess  /></PrivateRoute>}/>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<ErrorPage />} />

      </Routes>
    </Box>
  );
}




export default App;
